import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  placeTri,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo,
  placeBoldLeftText,
  toggle,
  placeBoldRightText,
  HiddenPt
} from '../../../../common/edliy_utils-geometric';

const Boxes = {
  box1: function () {
    JXG.Options.board.minimizeReflow = 'none';
    JXG.Options.point.showInfoBox=false;
    JXG.Options.point.highlight=false;
    JXG.Options.text.highlight=false;
    JXG.Options.text.fixed=true;
    JXG.Options.curve.highlight=false;
    JXG.Options.circle.highlight=false;
    JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
		var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-11, 16, 11, -6],keepaspectratio: true, axis:true, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false}, showCopyright:false, showNavigation:false});
 /////////////////////////////////////////////////////////////////////////////////
    makeResponsive(brd1);
    brd1.options.layer['image'] =30;
    brd1.options.layer['point'] =20;
    brd1.options.layer['text'] =20;
    brd1.options.layer['line'] =10;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
    placeTitle(brd1, 'Equation of Straight Line', '(Enter values of M & C in the equation: y = M x +C)');
    placeLogo(brd1);
    var analytics =placeEraseLeft(brd1);
    analytics.setLabel('To to Erase and Start Over')
    analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    analytics.on('over', function () {this.label.setAttribute({visible:true});});
    analytics.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////////////////////////////////////////
    var erase =placeStat(brd1);
    erase.setLabel('Tap to See Data & Parameters')
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
    ///////////////////////////////////////////////////////////////////////////
    var y =placeBoldLeftText(brd1, -9, 11.5, 'y =');
    //y.setAttribute({color:'red'});
    var m =placeInput(brd1, -8, 11.5, "0");
    //m.setAttribute({cssStyle:'color:red'});
    var x =placeBoldLeftText(brd1, -5.5, 11.5, ' x + ');
  //  x.setAttribute({color:'red'});
    var c =placeInput(brd1, -4.5, 11.5, "0");
  //  c.setAttribute({cssStyle:'color:red'});
    ////////////////////////////////////////////////////
    var li =placeSeg(brd1, [1.0, ()=>(1.0*m.Value()+1.0*c.Value())], [0, ()=>1.0*c.Value()], 3, 'black');
    var Horz =placeDash(brd1, [3.0, ()=>3.0*m.Value()+1.0*c.Value()], [4.0, ()=>3.0*m.Value()+1.0*c.Value()], 2, 'black');
    Horz.setAttribute({visible:()=>i==2});
    var Vert =placeDash(brd1, [4.0, ()=>3.0*m.Value()+1.0*c.Value()], [4.0, ()=>4.0*m.Value()+1.0*c.Value()], 2, 'black');
    Vert.setAttribute({visible:()=>i==2});
    var Text =placeBoldLeftText(brd1, 3.25, ()=>2.5*m.Value()+1.0*c.Value(), ()=>'M = '+ m.Value());
    Text.setAttribute({visible:()=>i==1});
    //////////////////////////////////////////
    //var hi = placeGlider(brd1, li, 1.0, ()=>(1.0*m.Value()+1.0*c.Value()) , 0, 'red', 'red');
    //var tri = brd1.create('slopetriangle', [li, hi],{baseline:{strokeColor:'red'}});
    //tri.setAttribute({visible:()=>i==1, label:{visible:()=>i==1}, baseline:{visible:false}, toppoint:{visible:false}, basepoint:{visible:false}});
    //tri.setLabelAttribute({visible:()=>i==1});
    /////////////////////////////////////////////////////////////
    analytics.on('down', function(){i=0; clearInputFields(brd1)});
    ////////////////////////////////////////////////
    var arr = placePoint(brd1, 0, ()=>1.*c.Value(), 6, 'black', 'black');
    var i=0;
    arr.setAttribute({visible:()=>i==1, color:'red', face:'cross'});
    var lb =placeBoldRightText(brd1, -1.4, ()=>1.*c.Value()-0.15, ()=>'C = '+ 1.*c.Value());
    lb.setAttribute({visible:()=>i==1});
    ///////////////////////////////////////////////
    //var cind =placeTapeVert(brd1, [0,0], [0, ()=>1.*c.Value()], 'C');
    //cind.setAttribute({ anchorX:'right', point1:{visible:false}, point2:{visible:false}});
    /////////////////////////////////////////////////////////////
    var arrow = placeImage(brd1, '/assets/pointing-hand.svg', -1.2, ()=>1.*c.Value()-0.65, 1, 0);
    arrow.setAttribute({visible:()=>i==1});
    erase.on('down', function(){i=toggle(i); console.log(i)})
    //placeImage(brd1, '/assets/slope.svg', 2, ()=>1.*c.Value()+2.*m.Value(), 1, 0);
    var slope =brd1.create('image', ['/assets/slope.svg', [2, ()=>1.*c.Value()+2.*m.Value()],[1.,()=>1.*m.Value()]]);
    slope.setAttribute({visible:()=>i==1});

	}
    }
export default Boxes;
